<template>
    <div class="overflow-hidden full-height full-width d-flex flex-column">
        <div>
            <!-- <h3>New Template</h3> -->
            <v-tabs background-color="grey" fixed-tabs color="primary">
                <v-tab :to="{ name: 'admin.users' }"><b>Users</b></v-tab>
                <v-tab :to="{ name: 'admin.competitions' }"><b>Competitions</b></v-tab>
            </v-tabs>
        </div>

        <div class="flex-shrink-1 overflow-hidden">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: "admin",

    props: {},

    components: {},

    data() {
        return {};
    },

    computed: {},

    methods: {},

    async mounted() {},
};
</script>

<style lang="scss"></style>
